import {
    CHECK_LOGIN,
    LOGIN_USER_SUCCESSFUL,
    API_ERROR,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    SET_USER_ACCESS_TOKEN,
    REGISTER_USER,
    REGISTER_USER_SUCCESSFUL,
    LOGIN_ERROR_FLAG,
    REGISTER_USER_FAILED,
} from "./actionTypes";

import { LOCAL_STORAGE_KEY_ACCESS_TOKEN } from "../../services/constants/LocalStorage";

const initialState = {
    loginError: "",
    message: null,
    loading: false,
    user: null,
    token: localStorage.getItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN) || null,
    loginErrFlag: false,
};

const login = (state = initialState, action) => {
    switch (action.type) {
        case CHECK_LOGIN:
            state = {
                ...state,
                loading: true,
            };
            break;
        case LOGIN_USER_SUCCESSFUL:
            state = {
                ...state,
                user: action.payload,
                loading: false,
            };
            break;

        case LOGIN_ERROR_FLAG:
            state = {
                ...state,
                loginErrFlag: action.payload,
            };
            break;

        case SET_USER_ACCESS_TOKEN:
            state = {
                ...state,
                token: action.payload,
                loading: false,
            };
            break;

        case LOGOUT_USER:
            if (window.location.href.includes("third-party/home")) {
                window.location.href = window.location.href.replace(
                    "home",
                    "signin"
                );
            }
            state = {
                ...state,
                user: null,
                token: null,
            };
            break;

        case LOGOUT_USER_SUCCESS:
            state = {
                ...state,
                user: null,
                token: null,
            };
            break;

        case API_ERROR:
            state = {
                ...state,
                loading: false,
                loginError: action.payload,
            };
            break;

        case REGISTER_USER:
            state = {
                ...state,
                user: null,
                loading: true,
                registrationError: null,
            };
            break;
        case REGISTER_USER_SUCCESSFUL:
            state = {
                ...state,
                user: action.payload,
                loading: false,
                registrationError: null,
            };
            break;
        case REGISTER_USER_FAILED:
            state = {
                ...state,
                loading: false,
                registrationError: action.payload,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default login;