import {
    ACTION_APP_ERROR,
    ACTION_APP_LOAD_STATUS_CHANGED
} from './actionTypes';

const initialState = {
    loaded: false,
    error: {
        status : false,
        code : null,
        message : null
    }
};

export const App = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_APP_LOAD_STATUS_CHANGED:
            return {
                ...state,
                loaded: action.payload
            };
        case ACTION_APP_ERROR:
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
};