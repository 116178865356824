const MAIN_URL = "https://api.makemarket.net/api/";

export const REGISTER = MAIN_URL + "register";
export const GET_CATEGORY_STORE = MAIN_URL + "store/category_story.get";
export const AUTH_SIGNIN_STORE = MAIN_URL + "auth.signin";
export const AUTH_OTP_STORE = MAIN_URL + "auth.exchangeToken";
export const API_URL_GET_STORE_DETAIL = MAIN_URL + "store/auth.current";



export const CREATE_STORE = MAIN_URL + "store.create";
export const ADD_INSTA_PAGE = MAIN_URL + "insta-page.add";