const Footer = () => {
    return ( 
        <footer>
			<div className="footer-top">
				<div className="pt-exebar">
					<div className="container">
						<div className="d-flex align-items-stretch">
							<div className="pt-logo mr-auto">
								<a href="index.html"><img src="/fa-logo.png" alt="" /></a>
							</div>
							<div className="pt-social-link">
								<ul className="list-inline m-a0">
									<li><a href="#" className="btn-link"><i className="fa fa-facebook"></i></a></li>
									<li><a href="#" className="btn-link"><i className="fa fa-twitter"></i></a></li>
									<li><a href="#" className="btn-link"><i className="fa fa-linkedin"></i></a></li>
								</ul>
							</div>
							<div className="pt-btn-join">
								<a href="#" className="btn ">عضو شوید</a>
							</div>
						</div>
					</div>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-lg-4 col-md-12 col-sm-12 footer-col-4">
							<div className="widget">
								<h5 className="footer-title">اشتراک در خبرنامه</h5>
								<p className="text-capitalize m-b20">از آخرین خبرهای مهم و مشورت های شغلی ما هر هفته باخبر شوید</p>
								<div className="subscribe-form m-b20">
									<form className="subscription-form"
										action=" " method="post">
										<div className="ajax-message"></div>
										<div className="input-group">
											<input name="email" required="required" className="form-control" placeholder="پست الکترونیک شما"
												type="email" />
											<span className="input-group-btn">
												<button name="submit" type="submit" className="btn"><i
														className="fa fa-arrow-left"></i></button>
											</span>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="col-12 col-lg-5 col-md-7 col-sm-12">
							<div className="row">
								<div className="col-4 col-lg-4 col-md-4 col-sm-4">
									<div className="widget footer_widget">
										<h5 className="footer-title">شرکت</h5>
										<ul>
											<li><a href="index.html">خانه</a></li>
											<li><a href="about-1.html">درباره ما</a></li>
											<li><a href="faq-1.html">پرسش و پاسخ</a></li>
											<li><a href="contact-1.html">تماس با ما</a></li>
										</ul>
									</div>
								</div>
								<div className="col-4 col-lg-4 col-md-4 col-sm-4">
									<div className="widget footer_widget">
										<h5 className="footer-title">در تماس باشید</h5>
										<ul>
											<li><a href="/">تماس با ما</a></li>
											<li><a href="/">بلاگ</a></li>
											<li><a href="/">نمونه کارها</a></li>
											<li><a href="/">رویداد</a></li>
										</ul>
									</div>
								</div>
								<div className="col-4 col-lg-4 col-md-4 col-sm-4">
									<div className="widget footer_widget">
										<h5 className="footer-title">دوره‌ها</h5>
										<ul>
											<li><a href="/">دوره‌ها</a></li>
											<li><a href="/">جزییات </a></li>
											<li><a href="/">عضویت</a></li>
											<li><a href="/">پروفایل</a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="col-12 col-lg-3 col-md-5 col-sm-12 footer-col-4">
							<div className="widget widget_gallery gallery-grid-4">
								<h5 className="footer-title">مجوزها</h5>
								<div className="row">
									<div className="col-6 col-md-6"><img src="/etemad.png" alt="" /></div>
									<div className="col-6 col-md-6"><img src="/samandehi1.png" alt="" /></div>
									<div className="col-6 col-md-6"><img src="/et.jpeg" alt="" /></div>
									
								</div>
								
								
									
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="footer-bottom">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 text-center"> © 1397  <span className="text-white">MakeMarket</span> تمامی حقوق محفوظ است</div>
					</div>
				</div>
			</div>
		</footer>
     );
}
 
export default Footer;