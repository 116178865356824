import { React, useState, useEffect, memo } from 'react';
import { Spinner } from 'reactstrap';
import { LOCAL_STORAGE_KEY_ACCESS_TOKEN } from '../services/constants/LocalStorage';
import { API_URL_GET_USER_DETAIL } from '../common/constants';
import { apiError, loginUserSuccessful, logoutUser, logoutUserSuccess } from '../store/auth/actions';
import { connect } from 'react-redux';
import axios from 'axios';
// import UrlUtils from '../services/utils/UrlUtils';
import store from '../store';

const BeforeEachRoute = (props) => {
//	const urlUtils = new UrlUtils();
	const storeId = 1;
	const token = localStorage.getItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN);

	const [completed, setCompleted] = useState(false);


	useEffect(() => {

		setCompleted(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	return (
		<props.layout authProtected={props.isAuthProtected}>
			{!completed || !props.workspaceLayout.isPreloader ? (
				<div className='card-loading-container'>
					<Spinner className='chat-messages-spinner-loading' color='info' />
				</div>
			) : (
				<props.component {...props.componentProps} />
			)}
		</props.layout>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.Login.user,
		workspaceLayout: state.Layout,
	};
};

const mapDispachToProps = (dispach) => {
	return {
		logoutUserSuccess: () => dispach(logoutUserSuccess()),
		loginUserSuccessful: (user) => dispach(loginUserSuccessful(user)),
		apiError: (error) => dispach(apiError(error)),
	};
};

export default connect(mapStateToProps, mapDispachToProps)(memo(BeforeEachRoute));