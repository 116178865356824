// import Profile from '../pages/Authentication/Profile';
// import ChangePassword from '../pages/Authentication/ChangePassword';
// import EmployeeHome from '../pages/Employee/home/index';


const routes = [
	// {
	// 	path: '/customer/home',
	// 	component: EmployeeHome,
	// 	type: 'customer',
	// },
	// {
	// 	path: '/customer/profile/change-password',
	// 	component: ChangePassword,
	// 	type: 'customer',
	// },
	// {
	// 	path: '/customer/profile',
	// 	component: Profile,
	// 	type: 'customer',
	// }
];

export default routes;