import {
    REGISTER_USER,
    REGISTER_USER_SUCCESSFUL,
    REGISTER_USER_FAILED,
    CHECK_LOGIN,
    LOGIN_USER_SUCCESSFUL,
    API_ERROR,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    SET_USER_ACCESS_TOKEN,
    AUTH_CURRENT_USER,
    LOGIN_ERROR_FLAG,
  } from "./actionTypes";
  
  export const registerUser = (user) => {
    return {
      type: REGISTER_USER,
      payload: { user },
    };
  };
  
  export const registerUserSuccessful = (user) => {
    return {
      type: REGISTER_USER_SUCCESSFUL,
      payload: user,
    };
  };
  
  export const registerUserFailed = (error) => {
    return {
      type: REGISTER_USER_FAILED,
      payload: error,
    };
  };
  
  export const checkLogin = (user, history) => {
    return {
      type: CHECK_LOGIN,
      payload: { user, history },
    };
  };
  
  export const loginUserSuccessful = (user) => {
    return {
      type: LOGIN_USER_SUCCESSFUL,
      payload: user,
    };
  };
  
  export const loginErrorFlag = (value) => {
    return {
      type: LOGIN_ERROR_FLAG,
      payload: value,
    };
  };
  
  export const apiError = (error) => {
    return {
      type: API_ERROR,
      payload: error,
    };
  };
  
  export const logoutUser = (history) => {
    return {
      type: LOGOUT_USER,
      payload: { history },
    };
  };
  
  export const logoutUserSuccess = () => {
    return {
      type: LOGOUT_USER_SUCCESS,
      payload: {},
    };
  };
  
  export const setUserAccessToken = (token) => {
    return {
      type: SET_USER_ACCESS_TOKEN,
      payload: token,
    };
  };
  
  export const authCurrentUser = () => {
    return {
      type: AUTH_CURRENT_USER,
    };
  };
  