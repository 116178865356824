import { combineReducers } from 'redux';

import Login from './auth/reducer';

import { Shop } from "./shop/reducer"

import { App } from "./app/reducer"


const rootReducer = combineReducers({

    Shop,
    Login,
    App
});

export default rootReducer;