
import Error404 from '../pages/Error404.jsx';

import publicRoutes from './public-routes.js';
import customerRoutes from './customer-routes.js';
import adminRoutes from './admin-routes.js';

const authAdminRoutes = [
	...adminRoutes,
	...customerRoutes,
  
	{
		path: '*',
		exact: true,
		component: Error404,
	},
];

export { authAdminRoutes, publicRoutes };