

const Header = () => {


    
    return ( 
    <header className="header rs-nav">
        <div className="top-bar">
            <div className="container">
                <div className="row d-flex justify-content-between">
                    <div className="topbar-left">
                        <ul>
                            <li><a href=""><i className="fa fa-question-circle"></i>از ما بپرسید</a></li>
                            <li><a href=""><i className="fa fa-envelope-o"></i>Support@MakeMarket.net</a></li>
                        </ul>
                    </div>
                    <div className="topbar-right">
                        <ul>
                            <li>
                                <select className="header-lang-bx">
                                    <option data-icon="flag flag-ir">فارسی</option>
                                    <option data-icon="flag flag-uk">انگلیسی</option>
                                    <option data-icon="flag flag-us">عربی</option>
                                </select>
                            </li>
                            <li><a href="/login">ورود</a></li>
                            <li><a href="/register">ثبت‌نام</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="sticky-header navbar-expand-lg">
            <div className="menu-bar clearfix">
                <div className="container clearfix">
                    <div className="menu-logo">
                        <a href="index.html"><img src="/fa-logo.png" alt="" /></a>
                    </div>
                    <button className="navbar-toggler collapsed menuicon justify-content-end" type="button" data-toggle="collapse"
                        data-target="#menuDropdown" aria-controls="menuDropdown" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                    <div className="secondary-menu">
                        <div className="secondary-inner">
                            <ul>
                                <li><a href="" className="btn-link"><i className="fa fa-facebook"></i></a></li>
                                <li><a href="" className="btn-link"><i className="fa fa-instagram"></i></a></li>
                                <li><a href="" className="btn-link"><i className="fa fa-linkedin"></i></a></li>
                               
                                <li className="search-btn"><button id="quik-search-btn" type="button" className="btn-link"><i className="fa fa-search"></i></button></li>
                            </ul>
                        </div>
                    </div>
                    <div className="nav-search-bar">
                        <form action="#">
                            <input name="search" type="text" className="form-control" placeholder="جستجو..." />
                            <span><i className="ti-search"></i></span>
                        </form>
                        <span id="search-remove"><i className="ti-close"></i></span>
                    </div>
                    <div className="menu-links navbar-collapse collapse justify-content-start" id="menuDropdown">
                        <div className="menu-logo">
                            <a href="index.html"><img src="assets/images/logo.png" alt=""/></a>
                        </div>
                        <ul className="nav navbar-nav">
                            <li className="active"><a href="">خانه  </a> </li>
                            <li><a href="">نمونه کارها </a></li>
                            <li className="add-mega-menu"><a href=""> محصولات <i className="fa fa-chevron-down"></i></a>
                                <ul className="sub-menu">
                                    <li><a href="">بازار ساز آنلاین</a></li>
                                </ul>
                            </li>
                            <li className="add-mega-menu"><a href=""> امکانات <i className="fa fa-chevron-down"></i></a>
                                
                            </li>
                            <li className="add-mega-menu"><a href=""> آکادمی <i className="fa fa-chevron-down"></i></a>
                                
                            </li>
                            <li><a href="">بلاگ</a></li>
                            <li><a href="">تعرفه ها</a></li>
                            <li><a href=""> ارتباط با ما</a></li>
                            <li><a href="">  درباره ما</a></li>                           
                        </ul>
                        <div className="nav-social-link">
                            <a href=""><i className="fa fa-facebook"></i></a>
                            <a href=""><i className="fa fa-google-plus"></i></a>
                            <a href=""><i className="fa fa-linkedin"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </header> );
}
 
export default Header;