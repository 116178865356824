// import { Redirect } from 'react-router-dom';
// import Profile from '../pages/Authentication/Profile';
// import ChangePassword from '../pages/Authentication/ChangePassword';
// import Home from '../pages/Admin/home';
 import Dashboard from '../pages/admin/dashboard';

// import {
//     adminRoutes 
// } from '@smartintegrity/risk-management-module/dist';

const routes = [
	{
		path: '/admin/dashboard',
		component: Dashboard,
		type: 'admin',
	},
	// {
	// 	path: '/admin/home',
	// 	component: Home,
	// 	type: 'admin',
	// },
	// {
	// 	path: '/admin/profile/change-password',
	// 	component: ChangePassword,
	// 	type: 'admin',
	// },
	// {
	// 	path: '/admin/profile',
	// 	component: Profile,
	// 	type: 'admin',
	// },
	// ...adminRoutes,
	// {
	// 	path: ['/admin', '/admin/*'],
	// 	component: () => <Redirect to='/admin/home' />,
	// 	type: 'admin',
	// },
];

export default routes;