import Index from './pages/landing';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { authAdminRoutes, publicRoutes } from './routes';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppRoute from './routes/route';
import Error404 from './pages/Error404';
import { useEffect, useState } from 'react';
import { LOCAL_STORAGE_KEY_ACCESS_TOKEN } from './services/constants/LocalStorage';
import axios from 'axios';
import { API_URL_GET_STORE_DETAIL } from './api/routes';
import { apiError, loginUserSuccessful, logoutUser, logoutUserSuccess } from './store/auth/actions';
import AddPageInsta from './pages/landing/AddPageInsta';

const queryClient = new QueryClient();

function App() {
  const [isShopValid, setIsShopValid] = useState(false); // Assuming initial state
  const [settings, setSettings] = useState({}); // Assuming initial state
  const [token, setToken] = useState(localStorage.getItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN));
	
  
  const shopId = 34;

	
 
	const [user, setUser] = useState([]);

	// const featuresSettings = props.settings;

	// const handleFetchAuthUser = async (shopId,token) => { 

  //   if(token && token != 'undefined'  ){

  //     try {

  //       const response = await axios.post(
  //         API_URL_GET_STORE_DETAIL,
  //         { shopId: shopId },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         },
  //       )
  //       if (response.data.ok === true) {
  //         loginUserSuccessful({
  //           ...response.data.data,
  //         });
  //         setUser(response.data.data);
  //         return response.data.data;
  //       }
       
  //     } catch (error) {  
        
  //       if (error.response && error.response.status === 500) {
  //         console.log(500);          
  //       } else {
  //         console.error('خطای رخ داده است:', error);
  //       }
        
  //       return false;
  //     }
  //   }
	// 	return false;
	// };




  // useEffect(() => {
  //     handleFetchAuthUser(shopId,token);
  // }, []);



  return (
    <>
      <ToastContainer position="bottom-left" />

      <QueryClientProvider client={queryClient}>
       
          <Routes>
            <Route path="/" element={<Index />} /> 
            <Route path="/instagram/add-page" element={<AddPageInsta />} /> 

            {isShopValid &&
              authAdminRoutes.map((route, index) => (
                <Route
                  key={index}
                  {...route} // Destructure route properties (path, layout, component, etc.)
                  isAuthProtected={true}
                  type={route.type}
                  settings={settings}
                  Component={route.component}
                />
              ))}

            {publicRoutes.map((route, index) => (            
              
              <Route
                key={index}
                {...route} // Destructure route properties                
                type={route.type}
                Component={route.component}
                settings={settings}
              />
            ))}

            <Route path="*" element={<Error404 />} />
          </Routes>
        
      </QueryClientProvider>
    </>
  );
}

export default App;