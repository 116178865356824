import axios from "axios";
import { useEffect, useState } from "react";
import { AUTH_OTP_STORE, AUTH_SIGNIN_STORE, GET_CATEGORY_STORE, REGISTER } from "../../../api/routes";
import { Link } from "react-router-dom";





const Modal = () => {

    var Success = "<h4>تبریک!</h4><span>شما با موفقیت فروشگاه آنلاین خود را راه‌اندازی کردید.</span><br /><span>از این پس، می‌توانید به راحتی وارد پنل کاربری خود شده و از تمامی امکانات فروشگاه‌ساز قدرتمند MakeMarket برای مدیریت و توسعه کسب‌وکار خود استفاده کنید. </span><br /><span><a href=>مرکز پشتیبانی</a></span><br /><span>با آرزوی موفقیت روزافزون برای شما</span><br />";

    var Errors = "خطا";

    const [nameShop, setNameShop] = useState("");
    const [categoryStore, setCategoryStore] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [emailOrMobile, setEmailOrMobile] = useState("");
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");

    const [loading, setLoading] = useState(false);


    const [errors, setErrors] = useState("is-invalid");

    const [categories, setCategories] = useState([]);
    const [currentStep, setCurrentStep] = useState(1);

    const [token, setToken] = useState("");



    const fetchCategories = async () => {
        try {
            const response = await axios.get(GET_CATEGORY_STORE);
            setCategories(response.data.data);
        } catch (error) {
            console.error(error);
        }
    };



    useEffect(() => {
        fetchCategories();
    }, []);


    const validateStep = (currentStep, data) => {

        if (currentStep == 1) {
            const errors = {};
            if (!data.storeName) {
                errors.storeName = 'نام فروشگاه الزامی است';
            }

            if (!data.storeCategory) {
                errors.storeCategory = 'انتخاب زمینه فعالیت الزامی است';
            }
            return errors;
        }

        if (currentStep == 2) {
            const errors = {};

            if (!data.firstName) {
                errors.firstName = 'نام شما الزامی است';
            }

            if (!data.lastName) {
                errors.lastName = 'نام خانوادگی شما الزامی است';
            }

            if (!data.email) {
                errors.email = 'ایمیل الزامی است';
            }

            return errors;
        }

        if (currentStep == 3) {
            const errors = {};            
            if (!data.plainPassword) {
                errors.plainPassword = 'کلمه عبور الزامی است';
            }
            if (repeatPassword !== password) {
                errors.plainPassword = 'کلمات عبور باید همسان باشند';

            }
            return errors;
        }
    }

    const handleNextStep = () => {
        
        localStorage.removeItem('ACCESS_TOKEN');

        const emailOrMobileResult = checkEmailOrMobile(emailOrMobile)

        var phone = "";
        var email = "";

        if (emailOrMobileResult.type == "email")
            email = emailOrMobileResult.value;
        if (emailOrMobileResult.type == "phone")
            phone = emailOrMobileResult.value;

        var data = {
            storeName: nameShop,
            storeCategory: categoryStore,
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phone,
            email: email,
            username: email,
            plainPassword: password
        };

        const errors = validateStep(currentStep, data);
        setErrors(errors);
       
        if (Object.keys(errors).length !== 0) {
            return;
        }

        if (currentStep < 4) {

            setCurrentStep(currentStep + 1);
            
            if (currentStep === 3) {                
                if (Object.keys(errors).length === 0) {

                    setLoading(true);                    

                    axios.post(REGISTER, data)
                        .then((response) => {
                          localStorage.setItem('ACCESS_TOKEN', response.data.data.token);
                          setCurrentStep(currentStep + 1); 
                        })
                        .catch((err) => {                             
                             if(err.response.data.errors.email){
                                errors.email = "از قبل یک حساب کاربری با این ایمیل وجود دارد";
                                setCurrentStep(currentStep - 1);                              
                             }                                
                        });
                }

            }
        }

        setLoading(false);
    };

    const handlePrevStep = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };



    const checkEmailOrMobile = (input) => {
        // الگوهای تشخیص شماره تلفن و ایمیل
        const phoneRegex = /^(?:(?:\+|0{0,2})98(\s*|\-){0,2})9\d{3}(\s*|\-){0,2}\d{4}$/; // الگوی شماره تلفن ایران
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // بررسی ورودی
        if (emailRegex.test(input)) {
            return { type: 'email', value: input };
        } else {
            return { type: 'phone', value: input };
        }
    }



    const renderStepContent = (step) => {

        switch (step) {
            case 1:
                return (
                    <div>
                        <div className="form-group">
                            <label htmlFor="name-shop">نام فروشگاه</label>
                            <input type="text" className={`form-control ${errors.storeName ? 'is-invalid' : ''}`} onChange={(e) => setNameShop(e.target.value)}  defaultValue="" placeholder="نام فروشگاه" />
                            <div className="invalid-feedback">
                                {errors.storeName}
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="category-shop"> زمینه فعالیت </label>
                            <select className={`form-control right ${errors.storeCategory ? 'is-invalid' : ''}`} id="categoryStore" onChange={(e) => setCategoryStore(e.target.value)}>                                
                                {categories.map((category) => {                                    
                                    return (                                        
                                        <option key={category.id} value={category.id}>
                                            {category.name}
                                        </option>
                                    )
                                }
                                )}
                            </select>
                            <div className="invalid-feedback">
                                {errors.storeCategory}
                            </div>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div>
                        <div className="form-group">
                            <label htmlFor="firstName"> نام </label>
                            <input type="text" className={`form-control ${errors.firstName ? 'is-invalid' : ''}`} onChange={(e) => setFirstName(e.target.value)}  placeholder="نام"  defaultValue=""/>
                            <div className="invalid-feedback">
                                {errors.firstName}
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="lastName"> نام خانوادگی </label>
                            <input type="text" className={`form-control ${errors.lastName ? 'is-invalid' : ''}`} onChange={(e) => setLastName(e.target.value)} aria-describedby="lastName" defaultValue="" placeholder="نام خانوادگی" />
                            <div className="invalid-feedback">
                                {errors.lastName}
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="emailOrMobile">ایمیل یا تلفن همراه</label>
                            <input type="text" className={`form-control ${errors.email ? 'is-invalid' : ''}`} onChange={(e) => setEmailOrMobile(e.target.value)} placeholder="ایمیل یا تلفن همراه " defaultValue="" />
                            <div className="invalid-feedback">
                                {errors.email}
                            </div>
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div>
                        <div className="form-group">
                            <label htmlFor="password"> رمز  عبور </label>
                            <input type="password" className={`form-control ${errors.plainPassword ? 'is-invalid' : ''}`} onChange={(e) => setPassword(e.target.value)} defaultValue={password} placeholder="رمز یکبار مصرف"/>
                            <div  className="invalid-feedback">
                                {errors.plainPassword}
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">تکرار رمز  عبور </label>
                            <input type="password" className={`form-control ${errors.plainPassword ? 'is-invalid' : ''}`} onChange={(e) => setRepeatPassword(e.target.value)} placeholder="رمز یکبار مصرف" />
                            <div className="invalid-feedback">
                                {errors.plainPassword}
                            </div>
                        </div>
                    </div>
                );
            case 4:
                return (                  
                    loading ? <> 
                            <span className="spinner-grow text-danger m-l2" role="status" aria-hidden="true"></span>
                            <span className="visually-hidden"> در حال ساخت بازار رویایی شما هستیم. آماده شید تا غرق در شگفتی شوید!</span>
                        </>
                    :<>                        
                        <span className="visually-hidden">با افتخار اعلام می‌کنیم که ساخت بازار بزرگ شما به پایان رسید. اکنون وقت آن است که کسب‌وکار خود را به جهانیان معرفی کنید.</span>
                    </>                    
                );
            default:
                return null;
        }
    };


    return (
        <div className="modal fade" style={{ "direction": "rtl", "textAlign": "right" }} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">شروع راه‌اندازی </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {renderStepContent(currentStep)}
                    </div>
                    <div className="modal-footer">
                        {currentStep > 1 &&
                            <button type="button" id="back-step" className="link btn-link m-l20" onClick={() => handlePrevStep()} disabled={currentStep === 1}>
                                بازگشت
                            </button>
                        }
                        {currentStep === 4 ? (
                        <Link
                            to={`/admin/stors`}
                            className={`btn btn-primary`}
                            style={{
                                cursor: 'default',
                            }}
                        >
                              ورود به مدیریت فروشگاه
                        </Link>
                        ) : (
                            <button type="button" id="next-step" className="btn btn-primary" onClick={() => handleNextStep()} disabled={currentStep === 4}>
                                بعدی
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;