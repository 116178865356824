import Footer from "../../components/layout/landing/Footer";
import Header from "../../components/layout/landing/Header";
import Modal from "../../components/layout/landing/Modal";


const Index = () => {


    return ( 
        <>
            <div className="page-wraper">
                <Header />

                <Modal />

                <div className="page-content bg-white">
			
			<div className="section-area section-sp1 ovpr-dark bg-fix online-cours"	style={{"backgroundImage":"url(assets/images/background/bg1.jpg)"}}>
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center text-white">
							<h2>Make Market</h2>
							<h5>فروشگاهی به وسعت جهان</h5>
							
								<div className="input-group m-b20" style={{"display":"flex","alignItems":"center","justifyContent":"center"}}>
									<button className="btn button-md radius-xl" data-toggle="modal" data-target="#exampleModal" style={{"fontSize":"30px"}}><b>کل دنیا، بازار تو! شروع کن!</b></button>
								</div>
							
						</div>
					</div>
					<div className="mw800 m-auto">
						<div className="row">
							<div className="col-md-4 col-sm-6">
								<div className="cours-search-bx m-b30">
									<div className="icon-box">
										<h3><i className="fa fa-university"></i> <span className="counter">5</span> هزار </h3>
									</div>
									<span className="cours-search-text"> هزار فروشگاه آنلاین</span>
								</div>
							</div>
							<div className="col-md-4 col-sm-6">
								<div className="cours-search-bx m-b30">
									<div className="icon-box">
										<h3><i className="ti-user"></i><span className="counter">30</span> هزار</h3>
									</div>
									<span className="cours-search-text">30,000 کاربر</span>
								</div>
							</div>
							<div className="col-md-4 col-sm-12">
								<div className="cours-search-bx m-b30">
									<div className="icon-box">
										<h3><i className="ti-layout-list-post"></i><span className="counter">200</span> هزار</h3>
									</div>
									<span className="cours-search-text">200 هزار کالا</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div className="content-block">		
				<div className="section-area section-sp2 popular-courses-bx">
					<div className="container">
						<div className="row">
							<div className="col-md-12 heading-bx right">
								<h2 className="title-head">محبوب‌ترین <span>قالب ها</span></h2>
								<p> محبوب‌ترین قالب‌های فروشگاهی، با طراحی واکنش‌گرا و سئو شده، تجربه خریدی روان و جذاب را برای کاربران فراهم می‌کنند. این قالب‌ها عموماً با افزونه‌های مختلف سازگاری دارند و امکان شخصی‌سازی گسترده‌ای را ارائه می‌دهند.</p>
							</div>
						</div>
						<div className="row">
							<div className="courses-carousel owl-carousel owl-btn-1 col-12 p-lr0">
								<div className="item">
									<div className="cours-bx">
										<div className="action-box">
											<img src="/upload/digi-store.png" alt=""/>
											<a href="#" className="btn">بیشتر بخوانید</a>
										</div>
										<div className="info-bx text-center">
											<h5><a href="#">قالب پیشرفته دیجی استور</a></h5>
											<span>Digi Market</span>
										</div>
										<div className="cours-more-info">
											<div className="review">
												<span>47 نظرات</span>
												<ul className="cours-star">
													<li className="active"><i className="fa fa-star"></i></li>
													<li className="active"><i className="fa fa-star"></i></li>
													<li className="active"><i className="fa fa-star"></i></li>
													<li className="active"><i className="fa fa-star"></i></li>
													<li className="active"><i className="fa fa-star"></i></li>
												</ul>
											</div>
											<div className="price">
												<del> 190 تومان </del>
												<h5> رایگان</h5>
											</div>
										</div>
									</div>
								</div>
								<div className="item">
									<div className="cours-bx">
										<div className="action-box">
											<img src="/upload/life-style.jpeg" alt=""/>
											<a href="#" className="btn">بیشتر بخوانید</a>
										</div>
										<div className="info-bx text-center">
											<h5><a href="#">قالب معرکه لایف استایل</a></h5>
											<span>Life Market</span>
										</div>
										<div className="cours-more-info">
											<div className="review">
												<span>196 نظرات</span>
												<ul className="cours-star">
													<li className="active"><i className="fa fa-star"></i></li>
													<li className="active"><i className="fa fa-star"></i></li>
													<li className="active"><i className="fa fa-star"></i></li>
													<li className="active"><i className="fa fa-star"></i></li>
													<li className="active"><i className="fa fa-star"></i></li>
												</ul>
											</div>
											<div className="price">
												<del> 190 تومان </del>
												<h5>  رایگان </h5>
											</div>
										</div>
									</div>
								</div>
								<div className="item">
									<div className="cours-bx">
										<div className="action-box">
											<img src="/upload/smart-market.png" alt=""/>
											<a href="#" className="btn">بیشتر بخوانید</a>
										</div>
										<div className="info-bx text-center">
											<h5><a href="#">قالب هوشمند اسمارت مارکت</a></h5>
											<span>Smart Market</span>
										</div>
										<div className="cours-more-info">
											<div className="review">
												<span>360 نظرات</span>
												<ul className="cours-star">
													<li className="active"><i className="fa fa-star"></i></li>
													<li className="active"><i className="fa fa-star"></i></li>
													<li className="active"><i className="fa fa-star"></i></li>
													<li className="active"><i className="fa fa-star"></i></li>
													<li className="active"><i className="fa fa-star"></i></li>
												</ul>
											</div>
											<div className="price">
												<del> 190 تومان </del>
												<h5> رایگان </h5>
											</div>
										</div>
									</div>
								</div>
								<div className="item">
									<div className="cours-bx">
										<div className="action-box">
											<img src="/upload/style-market.jpeg" alt=""/>
											<a href="#" className="btn">بیشتر بخوانید</a>
										</div>
										<div className="info-bx text-center">
											<h5><a href="#"> قالب جذاب استایل مارکت</a></h5>
											<span>Style Market</span>
										</div>
										<div className="cours-more-info">
											<div className="review">
												<span>253 نظرات</span>
												<ul className="cours-star">
													<li className="active"><i className="fa fa-star"></i></li>
													<li className="active"><i className="fa fa-star"></i></li>
													<li className="active"><i className="fa fa-star"></i></li>
													<li className="active"><i className="fa fa-star"></i></li>
													<li className="active"><i className="fa fa-star"></i></li>
												</ul>
											</div>
											<div className="price">
												<del> 190 تومان </del>
												<h5>رایگان  </h5>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="section-area section-sp2 bg-fix ovbl-dark join-bx text-center"	style={{"backgroundImage":"url(assets/images/background/bg1.jpg)"}}>
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="join-content-bx text-white">
									<h3 style={{"lineHeight":"49px"}}>رازهای موفقیت در تجارت الکترونیک را <br /> با خبرنامه MakeMarket کشف کنید</h3>
									<p>به جامعه MakeMarket بپیوندید و از دنیای گسترده‌ای از اطلاعات مفید در حوزه تجارت الکترونیک بهره‌مند شوید. با عضویت در خبرنامه ما، به جدیدترین اخبار، آموزش‌ها، و تحلیل‌های بازار دسترسی خواهید داشت. خبرنامه خود را شخصی‌سازی کنید و تنها اخبار مورد علاقه‌تان را دریافت نمایید.</p>
									<a href="#" className="btn button-md">عضو شوید</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="section-area section-sp1">
					<div className="container">
						<div className="row">
							<div className="col-lg-6 m-b30">
								<h2 className="title-head ">  اصول بنیادی <span className="text-primary"> MakeMarket  </span>
								</h2>
								<p>MakeMarket با تکیه بر هوش مصنوعی، نوآوری و مشتری‌مداری، تجربه‌ای متفاوت از فروشگاه‌سازی آنلاین را برای شما به ارمغان می‌آورد. ما با ارائه یک پلتفرم یکپارچه و کاربرپسند، به شما کمک می‌کنیم تا کسب‌وکارتان را به سطحی بالاتر ببرید. امنیت داده‌ها، شخصی‌سازی تجربه مشتری و ارائه خدمات پس از فروش حرفه‌ای، از جمله اصول بنیادی ما هستند.</p>
								<a href="#" className="btn button-md"> بیشتر بدانید ... </a>
							</div>
							<div className="col-lg-6">
								<div className="row">
									<div className="col-lg-6 col-md-6 col-sm-6 m-b30">
										<div className="feature-container">
											<div className="feature-md text-white m-b20">
												<a href="#" className="icon-cell"><img src="assets/images/icon/icon1.png" alt=""/></a>
											</div>
											<div className="icon-content">
												<h5 className="ttr-tilte"> هوش مصنوعی در خدمت تجارت</h5>
												<p>استفاده از هوش مصنوعی برای بهبود تصمیم‌گیری‌ها، افزایش فروش و ارائه خدمات بهتر به مشتریان، هسته اصلی برند MakeMarket است. </p>
											</div>
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-sm-6 m-b30">
										<div className="feature-container">
											<div className="feature-md text-white m-b20">
												<a href="#" className="icon-cell"><img src="assets/images/icon/icon2.png" alt=""/></a>
											</div>
											<div className="icon-content">
												<h5 className="ttr-tilte"> سادگی و کاربری آسان</h5>
												<p> MakeMarket ابزاری قدرتمند و در عین حال ساده برای ایجاد و مدیریت فروشگاه‌های آنلاین است.</p>
											</div>
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-sm-6 m-b30">
										<div className="feature-container">
											<div className="feature-md text-white m-b20">
												<a href="#" className="icon-cell"><img src="assets/images/icon/icon3.png" alt=""/></a>
											</div>
											<div className="icon-content">
												<h5 className="ttr-tilte">قابلیت اطمینان </h5>
												<p>  MakeMarket یک پلتفرم پایدار و قابل اعتماد برای ایجاد و مدیریت فروشگاه‌های آنلاین است.</p>
											</div>
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-sm-6 m-b30">
										<div className="feature-container">
											<div className="feature-md text-white m-b20">
												<a href="#" className="icon-cell"><img src="assets/images/icon/icon4.png" alt=""/></a>
											</div>
											<div className="icon-content">
												<h5 className="ttr-tilte">جهـان‌بیـنی ما</h5>
												<p>  دنیایی از امکانات بی‌نهایت را برای کسب‌وکارهای آنلاین فراهم می‌کند. با ما، شما نه تنها یک فروشگاه آنلاین، بلکه یک اکوسیستم هوشمند و یکپارچه خواهید داشت.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			
				<div className="section-area section-sp1 bg-fix ovbl-dark text-white" style={{"backgroundImage":"url(assets/images/background/bg1.jpg)"}}>
					<div className="container">
						<div className="row">
							<div className="col-lg-3 col-md-6 col-sm-6 col-6 m-b30">
								<div className="counter-style-1">
									<div className="text-white">
										<span className="counter">7</span><span>+</span>
									</div>
									<span className="counter-text">دوره‌های تکمیل شده</span>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6 col-6 m-b30">
								<div className="counter-style-1">
									<div className="text-white">
										<span className="counter">500</span><span>+</span>
									</div>
									<span className="counter-text">کسبه راضی</span>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6 col-6 m-b30">
								<div className="counter-style-1">
									<div className="text-white">
										<span className="counter">4</span><span>+</span>
									</div>
									<span className="counter-text">رشته‌ها</span>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6 col-6 m-b30">
								<div className="counter-style-1">
									<div className="text-white">
										<span className="counter">6</span><span>+</span>
									</div>
									<span className="counter-text">دوره‌های پیش‌رو</span>
								</div>
							</div>
						</div>
					</div>
				</div>


				<div className="section-area section-sp2">
					<div className="container">
						<div className="row">
							<div className="col-md-12 heading-bx right justifyContent">
								<h2 className="title-head text-uppercase">آنچه دیگران <span>می‌گویند</span></h2>
								<p> MakeMarket، آینده‌ی تجارت الکترونیک را رقم می‌زند. با بهره‌گیری از هوش مصنوعی پیشرفته، این پلتفرم قدرتمند، تجربه‌ای بی‌نظیر از فروشگاه‌سازی را برای شما به ارمغان می‌آورد. از ایجاد چندین فروشگاه با یک حساب کاربری و شخصی‌سازی کامل، تا اتوماسیون فرآیندهای فروش، تحلیل داده‌ها و پیش‌بینی رفتار مشتری، MakeMarket همه چیز را برای موفقیت کسب‌وکارتان فراهم می‌کند. با سیستم‌های حسابداری، انبارداری و لجستیک پیشرفته، به راحتی به اهداف فروش خود دست پیدا کنید. دوره‌های آموزشی متنوع، شما را در مسیر رشد و توسعه‌ی کسب‌وکارتان همراهی خواهند کرد. MakeMarket با قیمت‌های مناسب و پشتیبانی قوی، بهترین انتخاب برای راه‌اندازی و مدیریت فروشگاه آنلاین شماست. </p>
							</div>
						</div>
						<div className="testimonial-carousel owl-carousel owl-btn-1 col-12 p-lr0">
							<div className="item">
								<div className="testimonial-bx">
									<div className="testimonial-thumb">
										<img src="/assets/images/testimonials/pic4.jpg" alt=""/>
									</div>
									<div className="testimonial-info">
										<h5 className="name">مهرانا فردین</h5>
										<p> صاحب فروشگاه محصولات ارگانیک-</p>
									</div>
									<div className="testimonial-content">
										<p>من به دنبال یک پلتفرم بودم که بتونه نیازهای خاص فروشگاه محصولات ارگانیکم رو برطرف کنه. MakeMarket با امکانات مدیریتی قوی و تمرکز بر روی محصولات طبیعی، بهترین گزینه بود. از همه مهم‌تر، پشتیبانی فنیشون خیلی سریع و حرفه‌ای هست.</p>
									</div>
								</div>
							</div>
							<div className="item">
								<div className="testimonial-bx">
									<div className="testimonial-thumb">
										<img src="assets/images/testimonials/pic4.jpg" alt=""/>
									</div>
									<div className="testimonial-info">
										<h5 className="name">علیرضا آزم</h5>
										<p>برنامه نویس وب -</p>
									</div>
									<div className="testimonial-content">
										<p>به عنوان یک برنامه‌نویس، از تکنولوژی‌های استفاده شده در MakeMarket خیلی خوشم اومد. رابط کاربری خیلی ساده و intuitiv هست و امکانات زیادی برای شخصی‌سازی و توسعه فروشگاه وجود داره. هوش مصنوعی‌اش هم خیلی قدرتمنده و به نظر می‌رسه آینده‌ی فروشگاه‌سازی آنلاین رو متحول کنه.</p>
									</div>
								</div>
							</div>
							<div className="item">
								<div className="testimonial-bx">
									<div className="testimonial-thumb">
										<img src="assets/images/testimonials/pic4.jpg" alt=""/>
									</div>
									<div className="testimonial-info">
										<h5 className="name">آرش میرشریفی</h5>
										<p>  مدیر یک فروشگاه زنجیره‌ای -</p>
									</div>
									<div className="testimonial-content">
										<p>با استفاده از MakeMarket، توانستیم فروشگاه‌های آنلاینمون رو با سرعت و سهولت بیشتری راه‌اندازی کنیم. امکان مدیریت چندین فروشگاه از یک پنل، خیلی به ما کمک کرده. همچنین، تحلیل داده‌ها و گزارش‌های دقیق، به ما کمک می‌کنه تا تصمیم‌های بهتری برای کسب‌وکارمون بگیریم.</p>
									</div>
								</div>
							</div>
							<div className="item">
								<div className="testimonial-bx">
									<div className="testimonial-thumb">
										<img src="assets/images/testimonials/pic4.jpg" alt=""/>
									</div>
									<div className="testimonial-info">
										<h5 className="name"> سمیرا موحدی</h5>
										<p>  کارآفرین حوزه مد -</p>
									</div>
									<div className="testimonial-content">
										<p>MakeMarket به من کمک کرد تا خیلی سریع و آسان فروشگاه آنلاین لباسم رو راه اندازی کنم. تنوع قالب‌ها و امکان شخصی‌سازی خیلی خوب بود. هوش مصنوعی‌اش هم توی پیش‌بینی ترندها و پیشنهاد محصولات به مشتریام خیلی به من کمک می‌کنه. با MakeMarket، کسب‌وکارم رو به رشد قابل توجهی گذاشتم.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

                <Footer />
            </div>
        </>
     );
}
 
export default Index;