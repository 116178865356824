import { useEffect, useState } from "react";
import { ADD_INSTA_PAGE, GET_CATEGORY_STORE } from "../../api/routes";
import axios from "axios";

const AddPageInsta = () => {

    const [categories, setCategories] = useState([]);


    const [pageAddress, setPageAddress] = useState([]);
    const [titlePage, setTitlePage] = useState([]);
    const [employeeCode, setEmplyeeCode] = useState([]);
    const [categoryStore, setCategoryStore] = useState("");


    const [errors, setErrors] = useState("is-invalid");


    const fetchCategories = async () => {
        try {
            const response = await axios.get(GET_CATEGORY_STORE);
            setCategories(response.data.data);
        } catch (error) {
            console.error(error);
        }
    };


    const addPage = () => {

        var data = {
            pageAddress: pageAddress,
            storeCategory: categoryStore,
            titlePage: titlePage,
            employeeCode: employeeCode,
        };

        axios.post(ADD_INSTA_PAGE, data)
        .then((response) => {
            console.log(response);
                      
        })
        .catch((err) => {
                                        
             if(err.response.data.errors.pageAddress){
                errors.pageAddress = "ادرس پیج تکراری است";                                             
             }

             if(err.response.data.errors.employeeCode){
                errors.employeeCode = "کد شناسایی شما اشتباه است";                                            
             }

        });
    }


    useEffect(() => {
        fetchCategories();
    }, []);


    return ( 
        <>  
        <div className="container">
            <div className="row justify-content-md-center rtl ">
            <div className="col align-self-center">
                <div className="form-group">
                    <label htmlFor="employeeCode">  کد شناسایی </label>
                    <input type="number" className="form-control"  onChange={(e) => setEmplyeeCode(e.target.value)} />
                    <div className="invalid-feedback">
                        {errors.employeeCode}
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="pageAddress"> آدرس پیج </label>
                    <input type="text" className="form-control"  onChange={(e) => setPageAddress(e.target.value)} />
                    <div className="invalid-feedback">
                        {errors.pageAddress}
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="titlePage"> عنوان پیج </label>
                    <input type="text" className="form-control"  onChange={(e) => setTitlePage(e.target.value)}  />                    
                </div>
                <div className="form-group">
                    <label htmlFor="category-shop"> زمینه فعالیت </label>
                    <select className="form-control right" id="categoryStore" onChange={(e) => setCategoryStore(e.target.value)}>                                
                        {categories.map((category) => {                                    
                            return (                                        
                                <option key={category.id} value={category.id}>
                                    {category.name}
                                </option>
                            )
                        }
                    )}
                    </select>
                    <div className="invalid-feedback">
                        {errors.storeCategory}
                    </div>
                </div>
                <div className="form-group">
                    <button type="button" className="btn btn-primary" onClick={() => addPage()}>
                        ثبت پیج
                    </button>
                </div>
            </div>
            </div>
        </div>

        </>

     );
}
 
export default AddPageInsta;