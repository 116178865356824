// import Login from '../pages/Authentication/Login';
// import GetEmail from '../pages/Authentication/ResetPassword/GetEmail';
// import ResetPage from '../pages/Authentication/ResetPassword/ResetPage';
// import SetPasswordPage from '../pages/Authentication/SetPasswordPage';
// import Logout from '../pages/Authentication/Logout';
// import Error404 from '../pages/Error404';
// import FatalError from '../pages/FatalError';

import Home from "../pages/landing/index";



const routes = [

	{
		path: '/',
		component: Home,
	},

	// {
	// 	path: '/set-password/:token',
	// 	component: SetPasswordPage,
	// },
	// {
	// 	path: '/reset-password/:token',
	// 	component: ResetPage,
	// },
	// {
	// 	path: '/reset-password',
	// 	component: GetEmail,
	// },
	// {
	// 	path: '/signin',
	// 	component: Login,
	// },
	// {
	// 	path: '/logout',
	// 	component: Logout,
	// },
	// {
	// 	path: '/404',
	// 	component: Error404,
	// },
	// {
	// 	path: '/fatal-error',
	// 	component: FatalError,
	// },
];

export default routes;